@import "~normalize.css/normalize.css";

:root {
  --reach-dialog: 1;
}

:focus {
  outline: none;
}

html {
  box-sizing: border-box;
  font-size: 10px;
  color: #3a424f;
  background-color: #f4f5f7;
}

html *,
html *::before,
html *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "sofia", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  line-height: 1.3;
}

strong {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

svg {
  fill: currentColor;
  height: 1em;
  width: 1em;
  pointer-events: none;
}

h2 {
  margin: 0;
}

input {
  width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
}

select {
  background-color: transparent;
}

[data-user-value="true"] {
  font-weight: 800;
}

textarea {
  resize: none;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3b3b951a;
}
